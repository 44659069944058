<template>
    <div class="landing-page">
        <!-- header -->
        <div class="header">
            <div class="container d-flex align-items-center">
                <div class="menu">
                    <div class="logo">
                        <img class="img-logo-header" src="../../../assets/icons/football/ic_logoKickdun.png">
                    </div>
                    <div class="menu-list mobile">
                        <Dropdown class="language" v-model="lang" :options="langArr" optionLabel="label">
                            <template #value="slotProps">
                                <div class="country-item country-item-value">
                                    <img :src="slotProps.value.flag" class="flat-icon"/>
                                    <span class="lang-text">{{slotProps.value.label}}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div class="country-item country-item-value">
                                    <img :src="slotProps.option.flag" class="flat-icon"/>
                                    <span class="lang-text">{{slotProps.option.label}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <i class="pi pi-bars" @click="showMenuListItems = true" v-if="!showMenuListItems"></i>
                        <i class="pi pi-times" @click="showMenuListItems = false" v-else></i>
                    </div>
                    <ul class="menu-list desktop">
                        <li class="menu-items" v-for="(items, index) in menuItems" :key="index">
                            <a @click="scrollMenuItems($event, items.link)">{{items.name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="desktop download-menu">
                    <Dropdown class="language" v-model="lang" :options="langArr" optionLabel="label">
                        <template #value="slotProps">
                            <div class="country-item country-item-value">
                                <img :src="slotProps.value.flag" class="flat-icon"/>
                                <span class="lang-text">{{slotProps.value.label}}</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="country-item country-item-value">
                                <img :src="slotProps.option.flag" class="flat-icon"/>
                                <span class="lang-text">{{slotProps.option.label}}</span>
                            </div>
                        </template>
                    </Dropdown>
                    <div class="download-btn">
                        <span class="download-text">DOWNLOAD NOW</span>
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/ic_apple.png" class="download-icon" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/ic_android.png" class="download-icon" /></a>
                    </div>
                </div>
            </div>
            <div class="menu-list-items" v-if="showMenuListItems">
                <ul class="menu-list mobile">
                    <li class="menu-items" v-for="(items, index) in menuItems" :key="index">
                        <a @click="scrollMenuItems($event, items.link)">{{items.name}}</a>
                    </li>
                </ul>
                <div class="mobile download-menu">
                    <div class="download-btn">
                        <span class="download-text">DOWNLOAD NOW</span>
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/ic_apple.png" class="download-icon" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/ic_android.png" class="download-icon" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="head-banners">
            <!-- <div class="mr-0 background-header"></div> -->
            <img src="../../../assets/icons/football/img_bannerhome.png" style="width: 100%; height: 100%;" />
            <div class="container">
                <div class="text-banner col-md-6">
                    <div class="header-text-banner">
                        <div>Kickdun is Football and <br/>Futsal community service</div>
                    </div>
                    <div class="body-text-banner">
                        Are you looking for clubs and members to play with? Join us. <br/>You can quickly and easily create a club, find competitors, and enjoy matches.
                    </div>
                    <!--<div class="footer-text-banner">
                        <Button label="View more" class="p-button-success main-style-button" />
                    </div>-->
                </div>
                <div class="text-banner right col-md-6">
                    <img src="../../../assets/icons/football/banner_image.png" style="height: 85%;" />
                </div>
            </div>
        </div>
        <!-- body -->
        <div class="body">
            <div id="app-featureds">
                <div class="function-title">APP FEATUREDS</div>
                <div class="container">
                    <div class="function">
                        <div class="function-banner desktop">
                            <!-- <img class="function-img" src="../../../assets/icons/football/img_appscreen.png"/> -->
                            <slider ref="slider" :options="options">
                                <!-- slideritem wrapped package with the components you need -->
                                <slideritem v-for="(item,index) in imgArr" :key="index">
                                    <img class="function-img" :src="item.img"/>
                                </slideritem>
                                <!-- Customizable loading -->
                                <div slot="loading">loading...</div>
                            </slider>
                        </div>
                        <div class="function-box">
                            <div class="function-items" :class="`${[(functions.length-1), (functions.length-2)].includes(index) ? 'last-items' : ''}`" v-for="(items, index) in functions" :key="index">
                                <div class="items-header">
                                    <img class="items-header-icon" :src="items.icon"/>
                                </div>
                                <div class="items-body">
                                    <div class="items-title">
                                        {{items.title}}
                                    </div>
                                    <div class="items-content">
                                        {{items.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="work">
                <div class="function-title">HOW THIS APP WORK</div>
                <div class="container">
                    <div class="function">
                        <div class="partner-box" v-for="(partner, index) in partners" :key="index">
                            <div class="partner-header">
                                <img class="partner-logo" :src="partner.logo"/>
                            </div>
                            <div class="partner-sub-header">
                                <!-- <img class="partner-logo" :src="partner.logo"/> -->
                                {{ partner.title }}
                                <div class="partner-box-line"></div>
                            </div>
                            <div class="partner-body">
                                {{ partner.content }}
                            </div>
                            <!-- <div class="partner-footer">
                                <div class="partner-box-line"></div>
                                <a href="#">Techtronic Industries</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container banners">
                <div class="text-banner">
                    DOWNLOAD THE APP TO EXPERIENCE CONVENIENT FEATURES
                </div>
                <!-- <div class="download-btn"> -->
                    <!-- <span class="download-text">DOWNLOAD NOW</span>
                    <img src="../../../assets/icons/football/ic_apple.png" class="download-icon" />
                    <img src="../../../assets/icons/football/ic_android.png" class="download-icon" /> -->
                <!-- </div> -->
                <div class="d-flex mt-3 mb-3 justify-content-center">
                    <div class="download-icon mr-3">
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/app-chplay-1.png" /></a>
                    </div>
                    <div class="download-icon">
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/app-ios-1.png" /></a>
                    </div>
                </div>
            </div>

            
        </div>

        <!-- footer -->
        <div class="footer">
            <div class="container row m-auto pt-5">
                <div class="col-lg-6 col-12 pt-2 mb-4 px-0">
                    <div class="logo-footer">
                        <img class="img-logo-footer" src="../../../assets/icons/football/Logo_kickdun_white.png"/>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="about-us">
                        Kickdun, the ultimate mobile app for football enthusiasts! Kickdun is designed to bring the football community together, offering a seamless platform to create football clubs, find teammates, challenge opponents, and stay updated with the latest team news.
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="company-name">KICKDUN COMPANY</div>
                    <div class="company-info mt-2 mb-2">
                        <div class="email">Email: kickdungo@gmail.com</div>
                        <div class="policy"><a @click="goPolicy()">Privacy policy</a></div>
                        <div class="d-flex" style="margin-top: 10px;">
                            <div class="social-icon mr-3">
                                <a href="https://www.facebook.com/profile.php?id=61558079368197&sk=about" target="_blank"><img src="../../../assets/icons/football/ic_facebook.png" /></a>
                            </div>
                            <div class="social-icon">
                                <a href="https://www.instagram.com/kickdun" target="_blank"><img src="../../../assets/icons/football/ic_instagram.png" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="d-flex mb-5">
                        <div class="download-icon mr-3">
                            <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/app-chplay-1.png" /></a>
                        </div>
                        <div class="download-icon">
                            <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/app-ios-1.png" /></a>
                        </div>
                    </div>
                    <!--<div class="d-flex">
                        <div class="social-icon mr-3">
                            <a href="https://www.facebook.com/profile.php?id=61558079368197&sk=about" target="_blank"><img src="../../../assets/icons/football/ic_facebook.png" /></a>
                        </div>
                        <div class="social-icon">
                            <a href="https://www.instagram.com/kickdun" target="_blank"><img src="../../../assets/icons/football/ic_instagram.png" /></a>
                        </div>
                    </div>-->
                </div>

                <div class="col-12 px-0">
                    <div class="copy-right">
                        Copyright by KICKDUN @2024
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-shadow" v-if="showMenuListItems" @click="showMenuListItems = false"></div>
    </div>
</template>

<script>
import jq from "jquery";
import { slider, slideritem } from 'vue-concise-slider'
export default {
    name: "home",
    components: {
        slider,
        slideritem
    },
    data() {
        return {
            options: {
                currentPage: 0,
                speed: 300,
                autoplay: 2000,
                itemAnimation: true,
                centeredSlides: true,
                thresholdDistance: 100,
                thresholdTime: 300,
                loopedSlides: 2,
                slidesToScroll: 1,
                loop: true
            },
            imgArr: [
                {
                    //img: require("@/assets/icons/football/img_appscreen.png"),
                    img: require("@/assets/icons/football/img_community.png"),
                },
                {
                    //img: require("@/assets/icons/football/img_clubdetailscreen.png")
                    img: require("@/assets/icons/football/img_match.png")
                },
                {
                    //img: require("@/assets/icons/football/banner_image.png")
                    img: require("@/assets/icons/football/img_ranking.png")
                },
                {
                    img: require("@/assets/icons/football/img_review.png")
                },
            ],
            lang: {
                label: 'ENG',
                value: 'en',
                flag: require("@/assets/icons/football/flag_UK.png")
            },
            showMenuListItems: false,
            langArr: [
                {
                    label: 'ENG',
                    value: 'en',
                    flag: require("@/assets/icons/football/flag_UK.png")
                },
                {
                    label: 'VI',
                    value: 'vi',
                    flag: require("@/assets/icons/football/flag_VN.png")
                },
                {
                    label: 'KR',
                    value: 'kr',
                    flag: require("@/assets/icons/football/flag_KR.png")
                }
            ],
            menuItems: [
                {
                    link: "#app-featureds",
                    name: "APP FEATUREDS"
                },
                {
                    link: "#work",
                    name: "HOW THIS APP WORK"
                },
                // {
                //     link: "#work",
                //     name: "Đối tác"
                // }
            ],
            partners: [
                {   
                    title: "Create Football Clubs",
                    logo: require("@/assets/icons/football/football-badge-svgrepo-com.png"),
                    content: "Start your own football club with just a few taps. Customize your club's profile, including team name, logo, and colors. Invite friends or other users to join your club and manage team members effortlessly."
                },
                {
                    title: "Find Teammates",
                    logo: require("@/assets/icons/football/groups_black_24dp_outline.png"),
                    content: "Are you short of players for your next game? Use our app to search for and connect with potential teammates. Filter searches by location, skill level, and availability to find the perfect match for your team."
                },
                {
                    title: "Challenge Opponents",
                    logo: require("@/assets/icons/football/soccer-ball-svgrepo-com (2).png"),
                    content: "Ready to put your skills to the test? Find and challenge other teams in your area. Our app allows you to schedule matches, set locations, and communicate with opposing teams to arrange the best time for a game."
                },
                {
                    title: "Rankings and Leaderboards",
                    logo: require("@/assets/icons/football/ranking-svgrepo-com.png"),
                    content: "Track your team's performance and see how you stack up against others. Our ranking system is based on match results, allowing you to climb the leaderboard and gain recognition within the football community."
                }
            ],
            packages: [],
            typeArr: {
                "DAY": "ngày",
                "MONTH": "tháng",
                "YEAR": "năm",
                "WEEK": "tuần"
            },
            functions: [
                {
                    icon: require("@/assets/icons/football/ic_findingoppoent.png"),
                    title: "Finding Opponents & mercenaries",
                    content: "You can find an opposing team by selecting your desired region, skill level, gender, and age."
                },
                {
                    icon: require("@/assets/icons/football/ic_clubmanagement.png"),
                    title: "Club Management",
                    content: "We provide a club page where you can communicate with team members and manage your club."
                },
                {
                    icon: require("@/assets/icons/football/ic_community.png"),
                    title: "Community",
                    content: "Share football and futsal news and interesting stories."
                },
                {
                    icon: require("@/assets/icons/football/ic_clubranking.png"),
                    title: "Club Ranking & Review",
                    content: "Check the ranking of your club through the result of match. Your club's performance will continue to improve."
                }
            ]
        }
    },
    watch: {
        "lang"() {
            this.$router.push(`/${this.lang.value}`);
        },
        "$route"() {
            setTimeout(() => {
                const hash = Object.assign({}, this.$route.hash);
                if (hash) {
                    history.pushState("", document.title, window.location.pathname + window.location.search);
                }
            }, 500);
        }
    },
    async created() {
        setTimeout(() => {
            const hash = Object.assign({}, this.$route.hash);
            if (hash) {
                history.pushState("", document.title, window.location.pathname + window.location.search);
            }
        }, 500);
        jq("#planding .function").on('scroll',function(){
            var WindowLeft = jq("#planding .function").scrollLeft();
            var stop = false;
            jq('.planding-box').each(function(i){
                var left = jq('.planding-box').eq(i).offset().left;
                var eleWidth = jq('.planding-box').eq(i).width();
                var scrollLeft = (left + (eleWidth + (10*i)))*i;
                if (scrollLeft < 0) {
                    scrollLeft = 0;
                }
                if(WindowLeft <= scrollLeft && !stop
                ){
                    jq('.planding-box').removeClass('active');
                    jq('.planding-page-item').removeClass('active');
                    jq('.planding-nav .planding-page-item').eq(i).addClass('active');
                    jq('.planding-box').eq(i).addClass('active');
                    stop = true;
                }
            });
        });
    },
    methods: {
        goPolicy() {
            document.title = "Kickdun Policy";
            this.$router.push('/policy');
        },
        // changeLanguage () {
        //     if (this.lang_btn == 'en') {
        //         this.lang_btn = 'vi';
        //     } else {
        //         this.lang_btn = 'en';
        //     }
        // },
        async clickPlandingNav(e, id) {
            jq("#planding .function").animate({
                scrollLeft: '+='+jq(`#planding .function #page-${id}`).offset().left
            }, 0);
            e.stopPropagation();
        },
        async scrollMenuItems(e, link) {
            history.pushState("", document.title, window.location.pathname + window.location.search);
            this.showMenuListItems = false;
            jq("html").animate({
                scrollTop: (jq(link).offset().top-90)
            }, 300);
            e.preventDefault();
        },
    }
};
</script>